import logo from './preloader.gif';
import './App.css';
import { useEffect } from 'react';
const CloudClinicPartner = () => {
    useEffect(() => {
        let userAgent;
        userAgent = navigator.userAgent;
        let isIOS = Boolean(userAgent.match(
          /Macintosh|iPhone OS|CPU iPhone OS|iPhone|iPad|iPod|Mac OS X/i
        ))
        let isAndroid = Boolean(userAgent.match(
          /Linux|Windows NT|Android OS/i
        ))
    
        if (isIOS) {
          window.location.replace('https://apps.apple.com/us/app/cloudclinic-partner/id1668991494');
        }
        if (isAndroid) {
          window.location.replace('https://play.google.com/store/apps/details?id=com.cloudclinic.ng.cloudclinic_partner_app');
        }
    
      }, []);
      return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
          </header>
        </div>
      );
}

export default CloudClinicPartner