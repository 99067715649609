import logo from './preloader.gif';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import CloudClinicPartner from './cloudclinicpartner';
import Home from './home';
import  CampaignePage  from './campaigne';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/mycloudclinic' element={<CampaignePage />} />
      <Route path='/cloudclinicpartner' element={<CloudClinicPartner />} />
    </Routes>
  );
}

export default App;
