import logo from './preloader.gif';
import './App.css';
import { useEffect } from 'react';
import React from "react";

const CampaignePage = () => {
    useEffect(() => {
        window.location.replace(
            "https://cloudclinicmobile.page.link/Mg7K"
        );
    }, []);
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
        </div>
    );
};

export default CampaignePage
